@import '../theme.scss';

.edit-ping-template-dialog-footer {
  position: relative;
  overflow: hidden;
  & > * {
    margin: 0 !important;
  }
}

.edit-ping-template-dialog-buttons-wrapper {
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & > * {
    margin: $modal-footer-margin-between * .5;
  }

  transition: transform .25s ease-out;
  &.hidden {
    transform: translateY(-150%);
  }
}

.edit-ping-template-dialog-buttons-spacer {
  flex: 1;
  text-align: center;
}

.edit-ping-template-confirm-delete-buttons-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: $modal-inner-padding - $modal-footer-margin-between * .5;

  & > * {
    margin: $modal-footer-margin-between * .5;
  }

  transition: transform .25s ease-out;
  &.hidden {
    transform: translateY(150%);
  }
}
