@import '~bootstrap/scss/functions.scss';

$enable-negative-margins: true;

/** Theming */
$primary: #1b7ba4;
$secondary: #ff4081;
$gray: #424242;

$cyan: #0babef;

$gray-100: #f1f1f1;
$gray-200: #d4d4d4;
$gray-300: #b8b8b8;
$gray-400: #9c9c9c;
$gray-500: #808080;
$gray-600: #636363;
$gray-700: #474747;
$gray-800: #2a2a2a;
$gray-900: #1c1c1c;

$green: #0bef2a;
$green-100: tint-color($green, 80%);
$green-200: tint-color($green, 60%);
$green-300: tint-color($green, 40%);
$green-400: tint-color($green, 20%);
$green-500: $green;
$green-600: shade-color($green, 20%);
$green-700: shade-color($green, 40%);
$green-800: shade-color($green, 60%);
$green-900: shade-color($green, 80%);

$red: #ef0b54;
$red-100: tint-color($red, 80%);
$red-200: tint-color($red, 60%);
$red-300: tint-color($red, 40%);
$red-400: tint-color($red, 20%);
$red-500: $red;
$red-600: shade-color($red, 20%);
$red-700: shade-color($red, 40%);
$red-800: shade-color($red, 60%);
$red-900: shade-color($red, 80%);

$yellow: #ffeb3b;
$yellow-100: tint-color($yellow, 80%);
$yellow-200: tint-color($yellow, 60%);
$yellow-300: tint-color($yellow, 40%);
$yellow-400: tint-color($yellow, 20%);
$yellow-500: $yellow;
$yellow-600: shade-color($yellow, 20%);
$yellow-700: shade-color($yellow, 40%);
$yellow-800: shade-color($yellow, 60%);
$yellow-900: shade-color($yellow, 80%);

$amber: #ffc107;
$amber-100: tint-color($amber, 80%);
$amber-200: tint-color($amber, 60%);
$amber-300: tint-color($amber, 40%);
$amber-400: tint-color($amber, 20%);
$amber-500: $amber;
$amber-600: shade-color($amber, 20%);
$amber-700: shade-color($amber, 40%);
$amber-800: shade-color($amber, 60%);
$amber-900: shade-color($amber, 80%);

$orange: #ff9800;
$orange-100: tint-color($orange, 80%);
$orange-200: tint-color($orange, 60%);
$orange-300: tint-color($orange, 40%);
$orange-400: tint-color($orange, 20%);
$orange-500: $orange;
$orange-600: shade-color($orange, 20%);
$orange-700: shade-color($orange, 40%);
$orange-800: shade-color($orange, 60%);
$orange-900: shade-color($orange, 80%);

$body-bg: $gray-800;
$success: $green-300;
$body-color: #ffffff;
$link-color: shade-color($cyan, 10%);
$link-decoration: none;
$link-hover-decoration: underline;
$link-shade-percentage: 10%;
$list-group-bg: $gray-700;
$list-group-color: $gray-200;
$list-group-action-color: $gray-300;
$list-group-hover-bg: $gray-600;
$list-group-action-hover-color: white;
$list-group-action-active-bg: $gray-500;
$list-group-action-active-color: white;
$input-bg: #313131;
$input-disabled-bg: #232323; // right between gray 800 and 900

$modal-content-bg: $gray-700;
$btn-close-color: white;

@import '~bootstrap/scss/variables.scss';
@import '~bootstrap/scss/mixins';
