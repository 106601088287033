.app-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.main {
  flex-grow: 1;
  overflow: hidden;
  overflow-y: auto;
}
